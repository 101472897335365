import { ready } from '../sharedWithConsole/ready.ts'

type Mode = 'visible' | 'above' | 'below'

function checkVisible(elm: HTMLElement, argThreshold: number, argMode?: Mode) {
  // https://stackoverflow.com/a/5354536/151666
  const threshold = argThreshold || 0
  const mode = argMode || 'visible'

  const rect = elm.getBoundingClientRect()
  const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
  const above = rect.bottom - threshold < 0
  const below = rect.top - viewHeight + threshold >= 0

  if (mode === 'above') {
    return above
  }
  if (mode === 'below') {
    return below
  }
  return !above && !below
}

function setHidden(element: HTMLElement, hidden: boolean) {
  const newElement = element
  if (hidden) {
    newElement.setAttribute('hidden', '')
    newElement.style.display = 'none'
  } else {
    newElement.removeAttribute('hidden')
    newElement.style.display = 'block'
  }
  return newElement
}

function initializeRobotFilter() {
  const filterInput = document.getElementById('robotFilter') as HTMLInputElement | null
  const searchContainer = document.querySelector('.js-docs-nav-search') as HTMLElement | null
  const sidebarContainer = document.querySelector(
    '.js-sidebar-scrolling-container',
  ) as HTMLElement | null

  if (!filterInput || !searchContainer || !sidebarContainer) return

  // Handle scroll events
  sidebarContainer.addEventListener('scroll', () => {
    if (sidebarContainer.scrollTop > 10) {
      searchContainer.classList.add('docs-nav__search--scrolled')
    } else {
      searchContainer.classList.remove('docs-nav__search--scrolled')
    }
  })

  // Filter functionality
  filterInput.addEventListener('input', (e: Event) => {
    const searchTerm = (e.target as HTMLInputElement).value.toLowerCase()
    const robotItems = document.querySelectorAll<HTMLElement>(
      '.js-docs-sidebar-list--transcoding .docs-nav__item',
    )

    robotItems.forEach((item) => {
      const robotTitle = item.querySelector('.docs-nav__link')?.textContent?.toLowerCase() || ''
      if (robotTitle.includes(searchTerm)) {
        item.classList.remove('docs-nav__item--hidden')
      } else {
        item.classList.add('docs-nav__item--hidden')
      }
    })

    // Show/hide section titles and lists based on whether they have visible items
    const sections = document.querySelectorAll<HTMLElement>(
      '.js-docs-sidebar-list--transcoding .docs-nav__title',
    )
    sections.forEach((section) => {
      const nextList = section.nextElementSibling as HTMLElement | null
      if (nextList?.classList.contains('docs-nav__list')) {
        const hasVisibleItems = Array.from(
          nextList.querySelectorAll<HTMLElement>('.docs-nav__item'),
        ).some((item) => !item.classList.contains('docs-nav__item--hidden'))
        section.style.display = hasVisibleItems ? '' : 'none'
        nextList.classList.toggle('docs-nav__list--empty', !hasVisibleItems)
      }
    })
  })
}

function initializeTableHeaderShadows() {
  const tables = document.querySelectorAll('.js-robot-pricing-group-table')

  if (!tables.length) return

  const checkTableHeaders = () => {
    tables.forEach((table) => {
      const thead = table.querySelector('thead')
      if (!thead) return

      const tableRect = table.getBoundingClientRect()
      const headerRect = thead.getBoundingClientRect()

      // Check if we've scrolled past the top of the table and the thead is sticky
      const isSticky = headerRect.top <= 100 && tableRect.bottom > headerRect.bottom

      thead.classList.toggle('is-sticky', isSticky)
    })
  }

  // Check on scroll
  window.addEventListener('scroll', checkTableHeaders, { passive: true })

  // Initial check
  checkTableHeaders()
}

const runOnStart = () => {
  // Initialize robot filter
  initializeRobotFilter()

  // Initialize table header shadows
  initializeTableHeaderShadows()

  const activeSidebarLink: HTMLElement | null = document.querySelector('.docs-nav__link--active')

  if (activeSidebarLink) {
    const topPos = activeSidebarLink.offsetTop
    const sidebar: HTMLElement | null = document.querySelector('.js-sidebar-scrolling-container')

    if (!checkVisible(activeSidebarLink, 30) && sidebar) {
      sidebar.scrollTop = topPos - 50
    }
  }

  if (document.querySelector('.js-comparer')) {
    const compareVersionsEl: HTMLElement | null = document.querySelector('.js-compare-versions')
    if (!compareVersionsEl) return
    const compareVersionsElTargetString = compareVersionsEl.dataset.target
    if (!compareVersionsElTargetString) return

    const target = document.querySelector(compareVersionsElTargetString)
    if (!target) return

    compareVersionsEl.addEventListener('click', () => {
      target.classList.toggle('compare-enabled')
    })

    const showDifferencesEl: HTMLElement | null = document.querySelector(
      '.js-only-show-differences',
    )
    if (!showDifferencesEl) return

    const showDifferencesTargetString = showDifferencesEl.dataset.target
    if (!showDifferencesTargetString) return

    showDifferencesEl.addEventListener('click', () => {
      const showDifferencesTarget = document.querySelector(showDifferencesTargetString)
      if (!showDifferencesTarget) return

      for (const tr of showDifferencesTarget.querySelectorAll('tr.homogeneous-yes')) {
        tr.classList.toggle('hidden')
      }
      target.classList.toggle('diff-enabled')
    })
  }

  const mql = window.matchMedia('(max-width: 767px)')
  const isSmallScreen = mql.matches

  const sidebar: HTMLElement | null = document.querySelector('.js-docs-sidebar')

  if (!sidebar || !isSmallScreen) return

  const toggleButtons: NodeListOf<HTMLElement> = document.querySelectorAll('.js-toggle-docs-menu')

  toggleButtons.forEach((toggleButton) => {
    const { slug } = toggleButton.dataset
    toggleButton.addEventListener('click', (event) => {
      event.preventDefault()

      const subMenus: NodeListOf<HTMLElement> | null =
        sidebar.querySelectorAll('.js-docs-sidebar-list')
      const subMenuTarget = sidebar.querySelector(`.js-docs-sidebar-list--${slug}`)
      const shouldOpen = subMenuTarget ? subMenuTarget.hasAttribute('hidden') : false

      for (const subMenu of subMenus) {
        setHidden(subMenu, !(subMenuTarget === subMenu && shouldOpen))
      }

      setHidden(sidebar, !shouldOpen)
    })
  })

  const gStartedExtraLinksToggle = document.querySelector(
    '.js-toggle-more-docs-gstarted-links',
  ) as HTMLButtonElement

  const gStartedExtraLinksContainer = document.querySelector(
    '.js-gstarted-extra-links',
  ) as HTMLElement

  if (gStartedExtraLinksToggle && gStartedExtraLinksContainer) {
    gStartedExtraLinksToggle.addEventListener('click', () => {
      const isCurrentlyHidden = gStartedExtraLinksContainer.hasAttribute('hidden')
      setHidden(gStartedExtraLinksContainer, !isCurrentlyHidden)

      gStartedExtraLinksToggle.textContent = isCurrentlyHidden
        ? 'Show less sections'
        : 'Show more sections'
    })
  } else {
    console.error('Elements not found in the DOM')
  }
}

ready(() => {
  runOnStart()
})
